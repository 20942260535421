void function () {
    const articleContent = document.querySelector('.article-detail__content')
    const tableContentList = document.querySelector('.table-of-content__list') as HTMLElement
    if (!articleContent) return

    const h2Titles = articleContent.querySelectorAll('h2')

    h2Titles.forEach(title => {
        const strong = title.querySelector('strong')
        const span = title.querySelector('span')

        if (!strong || !span) {
            const warn = 'h2 title in .article-detail__content must have the \<strong\> and \<span\> elements. If this h2 element is not used for table of content, please consider using other title tags\n'
            return console.warn(warn, 'h2 element:\n', title)
        }

        const anchor = createTableContentItem(strong.innerText, String(span.textContent).trim())

        const id = encodeURIComponent(title.innerText)
        title.id = id
        anchor.href = `#${id}`

        tableContentList.append(anchor)
    })
}()

function createTableContentItem(subtitle: string, title: string) {
    const anchor = document.createElement('a')
    anchor.className = 'table-of-content__item frame-item'
    anchor.innerHTML = `
    <div class="table-of-content__item-title">${subtitle}</div>
    <div class="table-of-content__item-value">${title}</div>
    `
    return anchor
}