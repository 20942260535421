const articleItems = document.querySelectorAll('.article-item')
articleItems.forEach(item => {
    const shareBtn = item.querySelector('button[data-action="share"]') as HTMLElement

    shareBtn.addEventListener('click', () => {
        const title = item.querySelector<HTMLAnchorElement>('a.article-item__title')

        if (!title) return

        const data: ShareData = {
            title: title.innerText,
            url: title.href
        }

        navigator.share(data)
            .catch(err => console.error(err))
    })
})