import 'ui/buttons/scroll-top-btn'
import 'layout/general/header'
import 'layout/general/footer'
import 'pages/list'
import 'pages/detail'
import 'pages/author'
import 'features/fancybox'
void function() {
  const accordions = document.querySelectorAll('.popular, .recommendations, .table-of-content')

  accordions.forEach(i => {
    const title = i.querySelector('._title')

    if (!title) return
    title.addEventListener('click', () => i.classList.toggle('_active'))
  })
}()