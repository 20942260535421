void function () {
    const textarea = document.querySelector<HTMLTextAreaElement>('.comments .textarea')
    if (!textarea) return

    const comments = document.querySelectorAll('.comment')

    comments.forEach(comment => {
        const name = comment.querySelector('.comment__name') as HTMLElement
        const replyBtn = comment.querySelector('.comment__btn') as HTMLElement

        replyBtn.addEventListener('click', () => {
            textarea.scrollIntoView({behavior: 'smooth', block: 'center'})
            textarea.focus()
            textarea.value = `${name.innerText}, `
        })
    })
}()