import './parts/table-of-content'
import printJS from 'print-js'

void function () {
    const shareBtn = document.querySelector('.article-detail button[data-action="share"]')

    if (!shareBtn) return

    shareBtn.addEventListener('click', () => {
        const title = document.querySelector<HTMLElement>('.article-detail__title') as HTMLElement

        const data: ShareData = {
            title: title.innerText,
            url: window.location.href
        }

        navigator.share(data)
            .catch(err => console.error(err))
    })
}()

void function () {
    const printBtn = document.querySelector('.article-detail button[data-action="print"]')

    if (!printBtn) return

    printBtn.addEventListener('click', () => {
        const content = document.querySelector('.article-detail__body') as HTMLElement

        const contentCopy = content.cloneNode(true) as HTMLElement
        const img = contentCopy.querySelector('.article-detail__cover')
        if (img) img.outerHTML = ''

        printJS({printable: contentCopy.outerHTML, type: 'raw-html'})
    })
}()