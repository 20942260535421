import Swiper, { Navigation, Pagination } from "swiper";

const swiper = new Swiper(".swiper", {
  modules: [Navigation, Pagination],
  slidesPerView: 2,
  spaceBetween: 18,
  breakpoints: {
    600: {
      slidesPerView: 4,
    },
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  pagination: {
    el: ".swiper-pagination",
    type: "bullets",
    formatFractionCurrent: (num) => num
  },
});
