const articleCards = document.querySelectorAll<HTMLElement>('.article-card')

articleCards.forEach(card => {
    const shareBtn = card.querySelector('button[data-action="share"]') as HTMLElement

    shareBtn.addEventListener('click', () => shareHandler(card))
})

function shareHandler(card: HTMLElement) {
    const title = card.querySelector('.article-card__title') as HTMLAnchorElement

    const data: ShareData = {
        title: title.innerText,
        url: title.href
    }

    navigator.share(data)
        .catch(err => console.error(err))
}